import Dropdown from "stimulus-dropdown";
import { useClickOutside } from "stimulus-use";

export default class extends Dropdown {
  static targets = ["list"];

  connect() {
    super.connect();
    this.element[this.identifier] = this;
    useClickOutside(this, { element: this.menuTarget });
  }

  hide(event) {
    super.hide(event);
    this.element.classList.remove("open");
  }

  toggle(event) {
    this.element.classList.toggle("open");
    this.menuTarget.classList.toggle("hidden");
    this.updatePosition();
  }

  show(event) {
    this.element.classList.add("open");
    this.menuTarget.classList.remove("hidden");
    this.updatePosition();
  }

  updatePosition() {
    this.menuTarget.style.removeProperty('top');
    this.menuTarget.style.removeProperty('right');
    this.menuTarget.style.removeProperty('bottom');
    this.menuTarget.style.removeProperty('left');
    const w = window.innerWidth;
    const h = window.innerHeight;
    const togglerRect = this.element.getBoundingClientRect();
    const menuRect = this.menuTarget.getBoundingClientRect();
    if (w < menuRect.right) {
      this.menuTarget.style.right = 0
    } else {
      this.menuTarget.style.left = 0
    }
    if (h < menuRect.bottom) {
      this.menuTarget.style.top = `-${this.menuTarget.offsetHeight + 8}px`
    } else {
      this.menuTarget.style.top = `${togglerRect.offsetHeight + 8}px`
    }
  }

  clickOutside(e) {
    let rect = this.menuTarget.getBoundingClientRect();
    if (!(e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom)) {
      this.hide(e);
    }
  }
}
