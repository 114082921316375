import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["lotInput", "beginDateInput", "encadrementReferenceInput", "encadrementReferenceMajoreInput"]

  updateEncadrementValue() {
    let beginDate = this.beginDateInputTarget.value
    let lotId = this.lotInputTarget["form--collection-select--component"].value

    if (!beginDate || !lotId)
      return false;

    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content
      fetch(`/admin/lots/${lotId}/property_id`)
        .then(resp => resp.json())
        .then(data => {
          const {property_id} = data
          fetch(`/admin/properties/${property_id}/encadrement_value`, {
            method: "POST",
            body: JSON.stringify({lease_date: beginDate}),
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            credentials: "same-origin",
          })
            .then(resp => resp.json())
            .then(data => {
              const {reference_value, reference_value_majore} = data
              this.encadrementReferenceInputTarget.value = reference_value
              this.encadrementReferenceMajoreInputTarget.value = reference_value_majore
            })
        })
    } catch (error) {
    }
  }
}